<template>
  <div>
    <GenericTable
      ref="lista"
      name="lavagem automatizada"
      title="lavagens automatizadas"
      add-route="tipoLavagemAutomatizadaAdicionar"
      edit-route="tipoLavagemAutomatizadaEditar"
      :nofilters="true"
      :cols="['Nome', 'Descrição']"
      :cols-name="['nome', 'descricao']"
      :cols-map="i => [i.nome, i.descricao]"
      :route="tipoLavagemAutomatizadaRoute"
      :permissionsToWrite="['rw_esterilizacao']"
    />
  </div>
</template>
<script>
export default {
  data() {
    return {
      tipoLavagemAutomatizadaRoute: 'tipoLavagemAutomatizada',
    };
  },
};
</script>
